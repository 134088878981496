import { createRouter, createWebHistory } from 'vue-router'
import { setupLanguageRedirect, detectPreferredLanguage } from './languageMiddleware'
import { nextTick } from 'vue'
import i18n from '../i18n'
import posthog from 'posthog-js'

const routes = [
  {
    path: '/:lang',
    component: () => import('../layouts/MainLayout.vue'),
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang
      if (!['fr', 'en'].includes(lang)) {
        return next('/fr')
      }
      if (i18n.global.locale.value !== lang) {
        i18n.global.locale.value = lang
      }
      return next()
    },
    children: [
      {
        path: '',
        name: 'LandingPage',
        component: () => import('../views/landing-page/LandingPage.vue'),
        meta: {
          title: route => route.params.lang === 'fr' 
            ? 'Template Dossier de Compétences | DC Creator'
            : 'Skills Report Template | DC Creator'
        }
      },
      {
        path: 'conditions-generales-vente',
        name: 'CGV',
        component: () => import('../views/pages/ConditionsLegales.vue'),
        meta: {
          title: route => route.params.lang === 'fr'
            ? 'Conditions Générales de Vente | DC Creator'
            : 'Terms of Sale | DC Creator'
        }
      },
      {
        path: 'terms-of-sale',
        redirect: to => `/${to.params.lang}/conditions-generales-vente`
      },
      {
        path: 'conditions-generales-utilisation',
        name: 'CGU',
        component: () => import('../views/pages/ConditionsLegales.vue'),
        meta: {
          title: route => route.params.lang === 'fr'
            ? 'Conditions Générales d\'Utilisation | DC Creator'
            : 'Terms of Use | DC Creator'
        }
      },
      {
        path: 'terms-of-use',
        redirect: to => `/${to.params.lang}/conditions-generales-utilisation`
      },
      {
        path: 'mentions-legales',
        name: 'MentionsLegales',
        component: () => import('../views/pages/ConditionsLegales.vue'),
        meta: {
          title: route => route.params.lang === 'fr'
            ? 'Mentions légales | DC Creator'
            : 'Legal notices | DC Creator'
        }
      },
      {
        path: 'legal-notices',
        redirect: to => `/${to.params.lang}/mentions-legales`
      }
    ]
  },
  {
    path: '/',
    redirect: '/fr'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/fr'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0, behavior: 'smooth' }
  }
})

// Mise à jour dynamique des titres de pages
router.afterEach(async (to) => {
  // Attendre que la traduction soit mise à jour
  await nextTick()
  
  const title = typeof to.meta.title === 'function' 
    ? to.meta.title(to)
    : to.meta.title

  if (title) {
    document.title = title
  }
})

// Middleware pour PostHog
router.beforeEach(async (to, from, next) => {
  // Vérifier si l'utilisateur a accepté les cookies d'analyse
  const cookiePreferences = localStorage.getItem('cookiePreferences')
  const analyticsEnabled = cookiePreferences ? 
    JSON.parse(cookiePreferences).analytics : false
  
  // Capture de la navigation pour PostHog uniquement si l'utilisateur a accepté
  if (to.name && analyticsEnabled && posthog && typeof posthog.capture === 'function') {
    try {
      // S'assurer qu'un ID distinct existe
      if (!posthog.get_distinct_id()) {
        const anonymousId = 'anon_' + Math.random().toString(36).substring(2, 15)
        posthog.identify(anonymousId)
      }
      
      // Capturer l'événement de pageview
      posthog.capture('$pageview', {
        path: to.path,
        referrer: from.path,
        route_name: to.name,
      })
    } catch (e) {
      console.error('[PostHog] Error capturing pageview:', e)
    }
  }
  
  next()
})

setupLanguageRedirect(router)

export default router
