import i18n from '../i18n'

export function detectPreferredLanguage() {
  try {
    // Récupérer les langues du navigateur
    const browserLangs = navigator.languages || [navigator.language || navigator.userLanguage]
    
    // Vérifier si une des langues du navigateur commence par 'fr'
    const hasFrench = browserLangs.some(lang => 
      lang.toLowerCase().startsWith('fr')
    )
    
    // Retourner 'fr' si une langue française est détectée, sinon 'en'
    return hasFrench ? 'fr' : 'en'
  } catch (error) {
    console.error('Erreur lors de la détection de la langue:', error)
    return 'fr' // Retourne français par défaut en cas d'erreur
  }
}

export function setupLanguageRedirect(router) {
  router.beforeEach((to, from, next) => {
    // Si on est à la racine ou sur une route non définie
    if (to.path === '/' || !to.params.lang) {
      const preferredLang = detectPreferredLanguage()
      return next(`/${preferredLang}`)
    }
    
    // Si la langue n'est pas supportée
    if (!['fr', 'en'].includes(to.params.lang)) {
      const preferredLang = detectPreferredLanguage()
      return next(`/${preferredLang}`)
    }
    
    // Mettre à jour la locale i18n si nécessaire
    if (to.params.lang && i18n.global.locale.value !== to.params.lang) {
      i18n.global.locale.value = to.params.lang
    }
    
    next()
  })
} 