export default {
  navbar: {
    menu: {
      advantages: "Avantages",
      features: "Fonctionnalités",
      pricing: "Tarifs",
      contact: "Faisons connaissance"
    },
    buttons: {
      login: "Se connecter",
      tryFree: "Essai gratuit"
    }
  },
  hero: {
    title: {
      part1: "Créez des Dossiers de Compétences à votre image en",
      part2: "un Clic"
    },
    subtitle: "Grâce à l'IA, optimisez votre recrutement avec DC Creator qui transforme les CVs candidats en dossiers de compétences complets, détaillés et à votre image.",
    cta: "Essayez gratuitement"
  },
  features: {
    header: {
      overline: "FONCTIONNALITÉS",
      title: "Tout ce dont vous avez besoin pour vos dossiers de compétences",
      subtitle: "Une solution complète pour transformer, gérer et partager vos dossiers de compétences en quelques clics"
    },
    feature1: {
      chip: "Gestion des templates",
      title: "Gérez plusieurs templates de dossiers de compétences",
      benefit1: "Centralisation de tous vos dossiers de compétences",
      benefit2: "Templates pré-formatés disponibles",
      benefit3: "Personnalisation complète (logo, couleur, textes)"
    },
    feature2: {
      chip: "Conversion rapide",
      title: "CV en dossier de compétences en 2 clics",
      benefit1: "Upload simple de CV en PDF",
      benefit2: "Génération instantanée par IA",
      benefit3: "Export au format Word modifiable"
    },
    feature3: {
      chip: "Nouveauté",
      title: "Traduction automatique de vos dossiers de compétences",
      benefit1: "Traduction instantanée des CV par notre IA",
      benefit2: "Templates disponibles en plusieurs langues",
      benefit3: "Gain de temps considérable sur la traduction"
    },
    feature4: {
      chip: "Partage simplifié",
      title: "Envoi de liens personnalisés aux candidats",
      benefit1: "Génération autonome par les candidats",
      benefit2: "Export au format Word modifiable",
      benefit3: "Interface intuitive pour les candidats"
    },
    feature5: {
      chip: "IA avancée",
      title: "Classification intelligente des compétences techniques",
      benefit1: "Regroupement automatique par catégories",
      benefit2: "Analyse des expériences et formations",
      benefit3: "Calcul automatique des années d'expérience"
    }
  },
  advantages: {
    header: {
      badge: "POURQUOI CHOISIR DC CREATOR ?",
      title: "Optimisez votre processus de recrutement",
      description: "Une solution innovante qui révolutionne la création de dossiers de compétences. Plus rapide, plus efficace, plus professionnel."
    },
    columns: {
      0: {
        title: "Maximisez vos conversions",
        text: "Augmentez significativement votre taux de conversion des candidats grâce à un processus fluide et sans friction. Ne perdez plus jamais un talent à cause d'un processus complexe.",
        metrics: {
          value: "93%",
          label: "de taux de completion"
        }
      },
      1: {
        title: "Gagnez un temps précieux",
        text: "Réduisez drastiquement le temps de création des dossiers de compétences. Nos utilisateurs économisent en moyenne 27 minutes par dossier.",
        metrics: {
          value: "80%",
          label: "de temps gagné"
        }
      },
      2: {
        title: "Sécurité garantie",
        text: "Une solution 100% conforme RGPD et loi 25, avec chiffrement de bout en bout. Vos données sont entre de bonnes mains.",
        metrics: {
          value: "100%",
          label: "RGPD et LOI 25 compliant"
        }
      }
    }
  },
  pricing: {
    header: {
      title: "Testez gratuitement !",
      subtitle: "Sans engagement - Annulez à tout moment"
    },
    offers: {
      free: {
        title: "Gratuit",
        subtitle: "Démarrage",
        features: {
          0: "3 dossiers de compétences / mois",
          1: "1 utilisateur",
          2: "1 template de dossier de compétences",
          3: "Support technique"
        }
      },
      essential: {
        title: "Essentiel",
        subtitle: "Démarrage",
        features: {
          0: "10 DC / mois",
          1: "2 utilisateurs",
          2: "1 template personnalisé",
          3: "Support technique",
          4: "Traduction automatisée"
        },
        badge: "Idéal pour démarrer",
      },
      standard: {
        title: "Standard",
        subtitle: "Pro",
        popular: "Plus populaire",
        features: {
          0: "30 DC / mois",
          1: "3 utilisateurs",
          2: "Lien candidat externe",
          3: "Traduction automatisée",
          4: "4 modèles de DC personnalisés",
          5: "Support technique et accompagnement"
        }
      },
      enterprise: {
        title: "Entreprise",
        subtitle: "Sur mesure",
        features: {
          0: "Nombre de DC à définir ensemble",
          1: "Utilisateurs en fonction du besoin",
          2: "Lien candidat externe",
          3: "Traduction automatisée",
          4: "Modèles de DC personnalisés illimités",
          5: "Support technique et accompagnement"
        }
      }
    },
    cta: {
      free: "Essayez gratuitement !",
      essential: "Essayez gratuitement !",
      standard: "Essayez gratuitement !",
      enterprise: "Contactez nous"
    }
  },
  contact: {
    header: {
      title: "Rencontrez l'équipe qui fera votre succès",
      subtitle1: "Vous avez des questions ou vous souhaitez une démonstration personnalisée ?",
      subtitle2: "Notre équipe d'experts est là pour vous accompagner vers la réussite."
    },
    team: {
      damien: {
        name: "Damien",
        role: "CTO"
      },
      alexandre: {
        name: "Alexandre",
        role1: "Head of Customer Success",
        role2: "& Commercial Director"
      },
      emmanuel: {
        name: "Emmanuel",
        role: "Head of AI & Automation"
      }
    },
    buttons: {
      contact: "Parlons de votre projet",
      demo: "Réserver une démo gratuite"
    }
  },
  footer: {
    menu: {
      advantages: "Avantages",
      features: "Fonctionnalités",
      pricing: "Tarifs",
      contact: "Nous contacter"
    },
    legal: {
      legalNotice: "Mentions légales",
      copyright: "© 2024 - DC CREATOR - All Rights Reserved"
    }
  },
  process: {
    header: {
      badge: "SIMPLE ET RAPIDE !",
      title: "Créez votre dossier de compétences en 3 étapes",
      subtitle: "Un processus simple et rapide pour transformer vos CV en dossiers de compétences professionnels"
    },
    steps: {
      1: {
        title: "Sélection d'un template",
        description: "Choisissez parmi nos modèles professionnels celui qui correspond le mieux à vos besoins.",
        illustrationText: "Choix du template"
      },
      2: {
        title: "Personnalisation du template",
        description: "Ajoutez votre logo et adaptez les couleurs pour refléter l'identité de votre entreprise.",
        illustrationText: "Personnalisation complète"
      },
      3: {
        title: "Transformation du CV",
        description: "Importez le CV et obtenez un dossier de compétences complet et optimisé pour vos clients.",
        illustrationText: "DC prêt à envoyer"
      }
    },
    cta: {
      badge: "PROPULSÉ PAR L'IA",
      timeValue: "Moins de 3 minutes",
      timeLabel: "c'est tout ce qu'il vous faut",
      title: "Gagnez 80% de votre temps de préparation",
      subtitle: "Pourquoi passer des heures sur vos dossiers de compétences quand l'IA peut le faire en quelques minutes ?",
      benefit1: "Dossiers de compétences professionnels générés instantanément",
      benefit2: "Personnalisation complète à l'image de votre entreprise",
      benefit3: "100% satisfait ou remboursé",
      button: "Testez gratuitement dès maintenant",
      buttonMobile: "Essayer gratuitement",
      guarantee: "Sans engagement - Annulable à tout moment",
      ratingText: "+ 100 utilisateurs satisfaits",
      statLeft: {
        value: "1200+",
        label: "dossiers générés"
      },
      statRight: {
        value: "100%",
        label: "clients satisfaits"
      }
    }
  },
  testimonials: {
    header: {
      badge: "TÉMOIGNAGES",
      title: "Ce qu'en pensent nos clients",
      subtitle: "Découvrez comment DC Creator transforme le quotidien des recruteurs"
    },
    items: {
      0: {
        author: "Alice Dubois",
        position: "Responsable Recrutement",
        content: "DC Creator a révolutionné notre processus de recrutement. La génération automatisée des dossiers de compétences nous fait gagner un temps précieux, nous permettant de nous concentrer sur l'essentiel : les relations humaines."
      },
      1: {
        author: "François Martin",
        position: "DRH",
        content: "Un outil indispensable qui a transformé notre approche du recrutement. La centralisation et l'automatisation nous ont permis d'optimiser significativement nos processus."
      },
      2: {
        author: "Franck Laurent",
        position: "Business Developer",
        content: "Grâce à DC Creator, nous avons réduit de 70% le temps consacré à la création des dossiers de compétences. Un investissement qui a considérablement amélioré notre efficacité."
      }
    }
  },
  legal: {
    legalNotice: {
      title: "Mentions légales"
    },
    tabs: {
      cgu: "Conditions Générales d'Utilisation",
      cgv: "Conditions Générales de Vente"
    },
    cgv: {
      title: "Conditions Générales de Vente",
      lastUpdate: "Dernière mise à jour",
      updateDate: "03/02/2025",
      sections: {
        0: {
          title: "Objet",
          content: "Les présentes conditions générales de vente (CGV) régissent l'accès et l'utilisation du service DC Creator, permettant de générer des dossiers de compétences à partir de CV. Toute souscription à l'un des plans proposés implique l'acceptation pleine et entière de ces CGV. DC Creator est un service édité par ZEPHYR AI, SAS au capital social de 1000€, immatriculée au RCS de Paris sous le numéro SIREN 940 280 712, SIRET 94028071200016, dont le siège social est situé au  26 RUE ANDRE MESSAGER, 33400 TALENCE."
        },
        1: {
          title: "Plans d'Abonnement",
          content: "Nous proposons plusieurs formules d'abonnement : une version gratuite limitée à 3 utilisations par mois, une version essentielle à 10 utilisations par mois pour 2 utilisateurs, une version pro à 30 utilisations par mois pour 3 utilisateurs. Des plans personnalisés sont disponibles pour les entreprises, sur demande. Les prix sont indiqués en euros hors taxes. La TVA applicable est celle en vigueur au jour de la commande."
        },
        2: {
          title: "Paiements",
          content: "Les paiements sont effectués via Stripe par prélèvement automatique mensuel. Les abonnements sont sans engagement et peuvent être résiliés à tout moment. En cas de résiliation, le service reste accessible jusqu'à la fin du mois en cours, mais aucun autre prélèvement ne sera effectué. Conformément à l'article L441-10 du Code de commerce, tout retard de paiement entraînera l'application de pénalités de retard égales à trois fois le taux d'intérêt légal en vigueur ainsi qu'une indemnité forfaitaire pour frais de recouvrement de 40€."
        },
        3: {
          title: "Facturation",
          content: "Une facture électronique conforme à l'article 289 du Code général des impôts sera émise après chaque paiement. Cette facture détaillera le montant HT, le taux et le montant de la TVA applicable, ainsi que le montant TTC. Conformément à l'article L441-9 du Code de commerce, le délai de réclamation concernant les factures est de 30 jours à compter de leur date d'émission. Pour les clients professionnels soumis à l'autoliquidation de la TVA, la mention 'Autoliquidation' sera apposée sur la facture, conformément à l'article 242 nonies A de l'annexe II du CGI."
        },
        4: {
          title: "Résiliation",
          content: "Pour résilier votre abonnement, veuillez nous contacter par email à l'adresse suivante : {email}. Votre abonnement sera annulé à la fin du mois en cours sans frais supplémentaires. ZEPHYR AI se réserve le droit de résilier unilatéralement tout abonnement en cas de non-respect des présentes CGV, notamment en cas de non-paiement ou d'utilisation frauduleuse du service.",
          email: "contact@dc-creator.com"
        },
        5: {
          title: "Accès au Service",
          content: "L'accès au service est fourni immédiatement après la souscription à l'un des plans. Les utilisateurs peuvent gérer leur compte et suivre l'évolution de leurs utilisations mensuelles via leur tableau de bord. ZEPHYR AI s'engage à fournir ses meilleurs efforts pour assurer la disponibilité du service 24h/24 et 7j/7, sauf en cas de maintenance planifiée ou d'incident technique."
        },
        6: {
          title: "Modification des CGV",
          content: "Nous nous réservons le droit de modifier les présentes conditions à tout moment. Les utilisateurs seront informés de toute modification majeure par email. Les modifications entrent en vigueur immédiatement après leur publication sur notre site. En cas de désaccord avec les nouvelles conditions, le Client dispose d'un délai de 30 jours pour résilier son abonnement."
        },
        7: {
          title: "Limitation de Responsabilité",
          content: "La responsabilité de ZEPHYR AI est limitée aux dommages directs et prévisibles résultant d'un manquement à ses obligations contractuelles. ZEPHYR AI ne saurait être tenue responsable des dommages indirects tels que perte de données, perte de chance, perte de chiffre d'affaires ou atteinte à l'image. La responsabilité de ZEPHYR AI est plafonnée au montant des sommes perçues au titre de l'abonnement au cours des 12 derniers mois."
        },
        8: {
          title: "Propriété Intellectuelle",
          content: "ZEPHYR AI est titulaire de tous les droits de propriété intellectuelle relatifs au service DC Creator. L'abonnement confère uniquement un droit d'utilisation du service, sans transfert de propriété. Le Client s'engage à ne pas reproduire, copier, vendre, échanger, ou exploiter à des fins commerciales tout ou partie du service."
        },
        9: {
          title: "Protection des Données Personnelles",
          content: "ZEPHYR AI s'engage à respecter la réglementation applicable en matière de protection des données personnelles, notamment le Règlement Général sur la Protection des Données (RGPD) et la Loi Informatique et Libertés. Les modalités de traitement des données personnelles sont détaillées dans notre Politique de Confidentialité accessible sur notre site."
        },
        10: {
          title: "Loi Applicable et Juridiction Compétente",
          content: "Les présentes CGV sont régies par le droit français. En cas de litige, les parties s'efforceront de trouver une solution amiable. À défaut, les tribunaux de Paris seront seuls compétents, nonobstant pluralité de défendeurs ou appel en garantie."
        },
        11: {
          title: "Contact",
          content: "Pour toute question concernant ces conditions générales de vente, vous pouvez nous contacter à l'adresse suivante : {email}.",
          email: "contact@dc-creator.com"
        }
      },
      contact: {
        email: "contact@dc-creator.com"
      }
    },
    cgu: {
      title: "Conditions Générales d'Utilisation",
      lastUpdate: "Dernière mise à jour",
      updateDate: "03/02/2025",
      sections: {
        0: {
          title: "Préambule",
          content: "DC Creator est un service en ligne destiné aux professionnels des ressources humaines, permettant la conversion de CV en dossiers de compétences personnalisés. Le service est disponible en France et au Québec et est conçu pour aider les recruteurs à analyser les CV de candidats. Les présentes CGU ont pour objectif de définir les droits et obligations des utilisateurs, ainsi que de garantir la conformité aux lois applicables (Loi 25 au Québec et RGPD en France)."
        },
        1: {
          title: "Définitions",
          content: "<strong>DC Creator</strong> : Le prestataire du service en ligne accessible sur https://dc-creator.com, édité par ZEPHYR AI, SAS au capital social de 1000€, immatriculée au RCS de Paris sous le numéro SIREN 940 280 712, SIRET 94028071200016.<br><br><strong>Utilisateur</strong> : Toute personne physique ou morale utilisant le service de DC Creator, notamment les recruteurs et responsables RH.<br><br><strong>Service</strong> : La solution de conversion de CV en dossiers de compétences fournie par DC Creator.<br><br><strong>CV</strong> : Document contenant des informations professionnelles sur un candidat, uploadé par l'utilisateur.<br><br><strong>Dossier de compétences</strong> : Document généré par DC Creator à partir des informations extraites du CV.<br><br><strong>Données personnelles</strong> : Informations permettant d'identifier une personne physique (nom, prénom, courriel, société).<br><br><strong>Lien Candidat</strong> : Fonctionnalité permettant aux recruteurs d'envoyer un lien direct aux candidats pour qu'ils puissent générer eux-mêmes leur dossier de compétences.<br><br><strong>Candidat</strong> : Toute personne dont le CV est uploadé par un utilisateur (recruteur) ou qui utilise la fonctionnalité \"Lien Candidat\".<br><br><strong>Stripe</strong> : Service tiers utilisé pour la gestion des paiements.<br><br><strong>API Gemini</strong> : Modèle de traitement de langage naturel développé par Google, utilisé pour analyser et extraire des données des CV.<br><br><strong>Supabase</strong> : Service de stockage des données utilisateurs, hébergé à Francfort, Allemagne, et soumis au RGPD."
        },
        2: {
          title: "Objet des CGU",
          content: "Les présentes CGU ont pour objet de définir les conditions d'utilisation du service DC Creator par les utilisateurs, ainsi que les responsabilités de chaque partie en ce qui concerne la protection des données personnelles et la conformité aux lois applicables."
        },
        3: {
          title: "Conditions d'inscription et d'utilisation",
          content: "Pour accéder au service, l'utilisateur doit créer un compte en fournissant des informations exactes et à jour. L'utilisateur s'engage à préserver la confidentialité de ses identifiants de connexion et à signaler toute utilisation non autorisée de son compte.<br><br>En utilisant notre service, l'utilisateur garantit avoir obtenu le consentement explicite des candidats pour l'utilisation de leurs CV dans le cadre de DC Creator, conformément à l'article 6.1.a du RGPD et à l'article 14 de la Loi 25 du Québec.<br><br><strong>L'utilisateur garantit DC Creator contre toute action, réclamation ou recours intenté par un candidat ou un tiers relatif au contenu des CV fournis par l'utilisateur et aux informations extraites à partir de ces CV.</strong>"
        },
        4: {
          title: "Obligations de DC Creator",
          content: "DC Creator s'engage à :<br><br>- Fournir un accès continu et sécurisé au service, sous réserve des opérations de maintenance et des événements imprévus.<br>- Supprimer les CV des candidats immédiatement après leur traitement par l'API Gemini.<br>- Protéger les données personnelles des utilisateurs conformément à la Loi 25 (Québec) et au RGPD (France).<br>- Mettre en œuvre des mesures techniques et organisationnelles appropriées pour garantir un niveau de sécurité adapté au risque.<br>- Notifier à l'utilisateur toute violation de données personnelles dans les délais prévus par la réglementation applicable."
        },
        5: {
          title: "Utilisation de l'API Gemini (Google AI)",
          content: "<strong>Traitement des données par l'API Gemini</strong> :<br><br>Lors de l'utilisation du service, les CV des candidats uploadés par les utilisateurs ou via le Lien Candidat sont envoyés à l'API Gemini pour analyse et extraction des données. Les utilisateurs reconnaissent et acceptent que les informations contenues dans les CV (y compris des données personnelles) seront transmises à Google.<br><br><strong>Protection des données</strong> :<br><br>DC Creator ne conserve pas les CV après leur envoi à l'API Gemini. Les données sont supprimées immédiatement après le traitement. Google applique des mesures de sécurité avancées, y compris le chiffrement.<br><br><strong>Transferts internationaux de données</strong> :<br><br>L'utilisation de l'API Gemini implique un transfert international de données personnelles hors UE vers Google. Ce transfert est encadré par les Clauses Contractuelles Types de la Commission Européenne et des mesures complémentaires appropriées pour assurer un niveau de protection adéquat des données personnelles conformément au RGPD.<br><br><strong>Consentement des utilisateurs et des candidats</strong> :<br><br>Les utilisateurs doivent obtenir le consentement explicite des candidats avant d'utiliser leur CV dans DC Creator. Ce consentement doit être libre, spécifique, éclairé et univoque, conformément aux exigences du RGPD et de la Loi 25."
        },
        6: {
          title: "Stockage des données utilisateurs",
          content: "Les données des utilisateurs sont stockées sur Supabase, hébergé à Francfort, Allemagne, conformément aux exigences du RGPD.<br><br>Conformément aux articles 15 à 22 du RGPD et aux dispositions équivalentes de la Loi 25, les utilisateurs peuvent exercer leurs droits d'accès, de rectification, d'effacement, de limitation du traitement, de portabilité et d'opposition en envoyant un email à contact@dc-creator.com.<br><br>DC Creator s'engage à traiter toute demande dans un délai de 30 jours et à supprimer les données sous 30 jours, sauf obligation légale de conservation."
        },
        7: {
          title: "Fonctionnalité \"Lien Candidat\"",
          content: "Les recruteurs peuvent envoyer un lien aux candidats pour qu'ils génèrent eux-mêmes leur dossier de compétences. Avant utilisation, le candidat doit accepter les CGU et donner son consentement explicite au traitement de ses données personnelles.<br><br>Le recruteur demeure responsable d'informer préalablement le candidat de cette démarche et d'obtenir son accord initial avant l'envoi du lien, conformément au principe de transparence du RGPD et de la Loi 25."
        },
        8: {
          title: "Paiement et facturation",
          content: "Le service est accessible via différentes formules d'abonnement (gratuit, essentiel, standard, entreprise).<br><br>Les paiements sont effectués via Stripe, qui agit comme sous-traitant de traitement des données de paiement.<br><br>Les données bancaires ne sont pas stockées par DC Creator mais directement par Stripe, qui respecte la norme PCI DSS (Payment Card Industry Data Security Standard).<br><br><strong>L'utilisateur s'interdit toute rétro-ingénierie, tentative d'accès ou d'extraction des éléments protégés par les droits de propriété intellectuelle détenus par DC Creator.</strong>"
        },
        9: {
          title: "Droit de rétractation",
          content: "Conformément à l'article L221-18 du Code de la consommation, l'utilisateur bénéficie d'un droit de rétractation de 14 jours à compter de la souscription, sous réserve de ne pas avoir utilisé le service.<br><br>Pour exercer ce droit, l'utilisateur doit notifier sa décision par email à contact@dc-creator.com.<br><br>En cas de rétractation, DC Creator procédera au remboursement sous 14 jours après notification, en utilisant le même moyen de paiement que celui utilisé pour la transaction initiale."
        },
        10: {
          title: "Données personnelles et confidentialité",
          content: "En tant que responsable de traitement, DC Creator collecte uniquement les données nécessaires à la création du compte et à l'utilisation du service, conformément au principe de minimisation des données (article 5.1.c du RGPD).<br><br>Les CV ne sont pas conservés et sont supprimés après traitement, conformément au principe de limitation de la conservation (article 5.1.e du RGPD).<br><br>DC Creator s'engage à ne pas vendre, louer ou céder les données personnelles à des tiers, sauf obligation légale.<br><br>Pour plus d'informations sur le traitement des données personnelles, veuillez consulter notre Politique de Confidentialité accessible sur notre site."
        },
        11: {
          title: "Support client",
          content: "Pour toute question ou assistance relative au service, contactez notre équipe à contact@dc-creator.com.<br><br>DC Creator s'engage à répondre dans un délai de 48 heures ouvrées. <strong>Notre support client est disponible du lundi au vendredi de 9h à 17h (heure française), avec un délai de réponse de 48h ouvrées.</strong>"
        },
        12: {
          title: "Publicité et références clients",
          content: "DC Creator peut utiliser le nom et le logo des clients comme référence commerciale, sous réserve de leur accord préalable et écrit.<br><br>Cette autorisation peut être retirée à tout moment sur simple demande à contact@dc-creator.com."
        },
        13: {
          title: "Modification des CGU",
          content: "DC Creator se réserve le droit de modifier les présentes CGU. Les utilisateurs seront informés par email ou notification sur le site 15 jours avant leur entrée en vigueur.<br><br>En cas de désaccord avec les nouvelles conditions, l'utilisateur pourra résilier son abonnement avant leur entrée en vigueur. À défaut, les nouvelles conditions seront réputées acceptées."
        },
        14: {
          title: "Limitation de responsabilité",
          content: "DC Creator n'est pas responsable des dommages indirects (perte de données, manque à gagner, perte d'opportunité, atteinte à l'image) résultant de l'utilisation ou de l'impossibilité d'utiliser le service.<br><br>La responsabilité de DC Creator est limitée aux dommages directs et prévisibles, dans la limite des montants versés par l'utilisateur au cours des 12 derniers mois.<br><br>DC Creator ne saurait être tenu responsable du contenu des CV et des dossiers de compétences générés."
        },
        15: {
          title: "Loi applicable et juridiction",
          content: "Les présentes CGU sont régies par le droit français pour les utilisateurs en France, et par le droit québécois pour les utilisateurs au Canada.<br><br>En cas de litige, les parties s'efforceront de trouver une solution amiable. À défaut, les tribunaux compétents seront ceux de Paris (France) ou de Montréal (Canada), nonobstant pluralité de défendeurs ou appel en garantie."
        },
        16: {
          title: "Force majeure",
          content: "DC Creator ne saurait être tenu responsable de l'inexécution ou du retard dans l'exécution de ses obligations en cas de force majeure, telle que définie par la jurisprudence des tribunaux français et québécois, notamment en cas de catastrophes naturelles, pandémies, attaques informatiques massives, défaillances des réseaux de télécommunication, ou décisions des autorités gouvernementales."
        }
      }
    }
  },
  cookies: {
    message: "Nous utilisons des cookies pour améliorer votre expérience sur notre site.",
    detailedMessage: "Ce site utilise des cookies pour améliorer votre expérience, analyser le trafic et personnaliser le contenu. Vous pouvez choisir ceux que vous acceptez.",
    moreInfo: "En savoir plus",
    accept: "Accepter",
    acceptAll: "Tout accepter",
    reject: "Refuser",
    rejectAll: "Tout refuser",
    essentialOnly: "Essentiels uniquement",
    settings: {
      title: "Paramètres des cookies",
      description: "Vous pouvez choisir les catégories de cookies que vous souhaitez activer. Les cookies essentiels sont nécessaires au fonctionnement du site et ne peuvent pas être désactivés.",
      save: "Enregistrer",
      cancel: "Annuler",
      essential: {
        title: "Cookies essentiels",
        description: "Nécessaires au fonctionnement du site. Ils permettent l'utilisation des fonctionnalités de base comme la navigation et l'accès aux zones sécurisées."
      },
      analytics: {
        title: "Cookies d'analyse",
        description: "Nous permettent de comprendre comment vous utilisez le site afin de l'améliorer (Google Analytics, PostHog)."
      },
      chat: {
        title: "Cookies de chat",
        description: "Permettent le fonctionnement du chat en direct pour vous offrir une assistance (Tawk.to)."
      }
    }
  },
  domains: {
    badge: "LES SECTEURS D'ACTIVITÉS",
    title: "Votre dossier de compétences pour tous les secteurs",
    subtitle: "Que vous recrutiez dans l'IT, la finance ou le BTP, notre IA s'adapte à tous les profils et valorise chaque parcours professionnel",
    items: {
      0: "Achats & Logistique",
      1: "IT & Digital",
      2: "Finance & Comptabilité",
      3: "BTP & Ingénierie",
      4: "Santé & Médical",
      5: "Et bien plus encore"
    },
    more: "Et tous les autres secteurs d'activité ! Notre outil s'adapte à vos besoins spécifiques, contactez-nous pour en savoir plus.",
    cta: "Je teste gratuitement"
  }
} 