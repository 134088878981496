<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { watch } from 'vue'

const { locale } = useI18n()

const updateHead = (lang) => {
  useHead({
    htmlAttrs: { lang },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1' },
      { 
        name: 'description', 
        content: lang === 'fr' 
          ? 'Créez facilement votre dossier de compétences avec nos templates professionnels. Solution simple et rapide pour les recruteurs en France et au Québec.'
          : 'Easily create your skills report with our professional templates. Simple and fast solution for recruiters in France and Quebec.' 
      },
      { name: 'robots', content: 'index, follow' },
      { 
        property: 'og:title', 
        content: lang === 'fr'
          ? 'Template Dossier de Compétences | DC Creator'
          : 'Skills Report Template | DC Creator'
      },
      { 
        property: 'og:description',
        content: lang === 'fr'
          ? 'Créez facilement votre dossier de compétences avec nos templates professionnels.'
          : 'Easily create your skills report with our professional templates.'
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:locale', content: lang },
      { property: 'og:locale:alternate', content: lang === 'fr' ? 'en' : 'fr' },
      {
        name: 'keywords',
        content: lang === 'fr' 
          ? 'dossier de compétences, template CV, recrutement France, recrutement Québec, IA recrutement'
          : 'skills report, CV template, recruitment France, recruitment Quebec, AI recruitment'
      },
      {
        name: 'geo.placename',
        content: lang === 'fr' ? 'France, Québec' : 'France, Quebec'
      },
      {
        name: 'geo.region',
        content: lang === 'fr' ? 'FR, CA-QC' : 'FR, CA-QC'
      },
      {
        name: 'language',
        content: lang === 'fr' ? 'French' : 'English'
      }
    ],
    link: [
      { 
        rel: 'canonical',
        href: `https://dc-creator.com/${lang}`
      },
      {
        rel: 'alternate',
        hreflang: 'fr',
        href: 'https://dc-creator.com/fr'
      },
      {
        rel: 'alternate',
        hreflang: 'en',
        href: 'https://dc-creator.com/en'
      },
      {
        rel: 'alternate',
        hreflang: 'x-default',
        href: 'https://dc-creator.com'
      },
      {
        rel: 'alternate',
        hreflang: 'fr-FR',
        href: 'https://dc-creator.com/fr'
      },
      {
        rel: 'alternate',
        hreflang: 'fr-CA',
        href: 'https://dc-creator.com/fr'
      },
      {
        rel: 'alternate',
        hreflang: 'en-GB',
        href: 'https://dc-creator.com/en'
      },
      {
        rel: 'alternate',
        hreflang: 'en-US',
        href: 'https://dc-creator.com/en'
      }
    ]
  })
}

watch(() => locale.value, (newLocale) => {
  updateHead(newLocale)
}, { immediate: true })
</script>

<style>
.background-gradient {
  background: linear-gradient(to bottom, #fff9ed, #fff9ed);
}

.section-title {
  margin-top: 40px;
  color: #1f1f47;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
}
</style>
