import posthog from 'posthog-js'

export default {
  install(app) {
    // Initialisation de PostHog avec opt_out activé par défaut
    posthog.init(
      'phc_EJZILzxhvhApM96mVicgHf0HgIM2vrnpKxjM56UH8xH',
      {
        api_host: 'https://eu.i.posthog.com',
        opt_out_capturing_by_default: true, // Désactiver par défaut (conformité RGPD)
        capture_pageview: false, // Ne pas capturer automatiquement les pages vues
        persistence: 'localStorage', // Utiliser localStorage au lieu des cookies
        disable_session_recording: true, // Désactiver l'enregistrement par défaut
        respect_dnt: true, // Respecter l'option "Do Not Track"
        loaded: (posthog) => {
          console.log('[PostHog] Initialized')
        }
      }
    )
    
    // Vérifier le consentement existant
    const cookiePreferences = localStorage.getItem('cookiePreferences')
    if (cookiePreferences) {
      try {
        const preferences = JSON.parse(cookiePreferences)
        if (preferences.analytics) {
          posthog.opt_in_capturing() // Activer si l'utilisateur a déjà consenti
          console.log('[PostHog] Enabled based on existing preferences')
        }
      } catch (e) {
        console.error('[PostHog] Error processing cookie preferences', e)
      }
    }
    
    // Rendre PostHog disponible globalement dans l'application
    app.config.globalProperties.$posthog = posthog
  }
}