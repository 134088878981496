export default {
  navbar: {
    menu: {
      advantages: "Benefits",
      features: "Features",
      pricing: "Pricing",
      contact: "Get in touch"
    },
    buttons: {
      login: "Login",
      tryFree: "Start free trial"
    }
  },
  hero: {
    title: {
      part1: "Create Professional Skills Reports that reflect your brand in just",
      part2: "one Click"
    },
    subtitle: "Optimize your recruitment process with DC Creator's AI, transforming candidate CVs into comprehensive, detailed skills reports that match your brand identity.",
    cta: "Try it for free"
  },
  features: {
    header: {
      overline: "FEATURES",
      title: "Everything you need for your skills reports",
      subtitle: "A complete solution to transform, manage and share your skills reports in just a few clicks"
    },
    feature1: {
      chip: "Template Management",
      title: "Manage multiple skills report templates",
      benefit1: "Centralize all your skills reports",
      benefit2: "Pre-formatted templates available",
      benefit3: "Full customization (logo, color, text)"
    },
    feature2: {
      chip: "Quick Conversion",
      title: "CV to skills report in 2 clicks",
      benefit1: "Simple PDF CV upload",
      benefit2: "Instant AI generation",
      benefit3: "Export to editable Word format"
    },
    feature3: {
      chip: "New Feature",
      title: "Automatic translation of your skills reports",
      benefit1: "Instant CV translation by our AI",
      benefit2: "Templates available in multiple languages",
      benefit3: "Significant time savings on translation"
    },
    feature4: {
      chip: "Simplified Sharing",
      title: "Send personalized links to candidates",
      benefit1: "Self-service generation by candidates",
      benefit2: "Export to editable Word format",
      benefit3: "User-friendly candidate interface"
    },
    feature5: {
      chip: "Advanced AI",
      title: "Smart technical skills classification",
      benefit1: "Automatic categorization",
      benefit2: "Experience and training analysis",
      benefit3: "Automatic calculation of years of experience"
    }
  },
  advantages: {
    header: {
      badge: "WHY CHOOSE DC CREATOR?",
      title: "Optimize your recruitment process",
      description: "An innovative solution that revolutionizes skills report creation. Faster, more efficient, more professional."
    },
    columns: [
      {
        title: "Maximize your conversions",
        text: "Significantly increase your candidate conversion rate with a smooth, frictionless process. Never lose talent due to a complex process again.",
        metrics: {
          value: "93%",
          label: "completion rate"
        }
      },
      {
        title: "Save precious time",
        text: "Drastically reduce the time spent creating skills reports. Our users save an average of 27 minutes per report.",
        metrics: {
          value: "80%",
          label: "time saved"
        }
      },
      {
        title: "Guaranteed security",
        text: "A 100% GDPR and Bill 25 compliant solution with end-to-end encryption. Your data is in safe hands.",
        metrics: {
          value: "100%",
          label: "GDPR compliant"
        }
      }
    ]
  },
  pricing: {
    header: {
      title: "Try it for free!",
      subtitle: "No commitment - Cancel anytime"
    },
    offers: {
      free: {
        title: "Free",
        subtitle: "Starter",
        features: {
          0: "3 skills reports / month",
          1: "1 user",
          2: "1 skills report template",
          3: "Technical support"
        }
      },
      essential: {
        title: "Essential",
        subtitle: "Starter",
        features: {
          0: "10 uses / month",
          1: "2 users",
          2: "1 custom template",
          3: "Technical support",
          4: "Automated translation"
        },
        badge: "Perfect to start",
      },
      standard: {
        title: "Standard",
        subtitle: "Pro",
        popular: "Most popular",
        features: {
          0: "30 uses / month",
          1: "3 users",
          2: "External candidate link",
          3: "Automated translation",
          4: "4 custom SR templates",
          5: "Technical support and guidance"
        }
      },
      enterprise: {
        title: "Enterprise",
        subtitle: "Custom",
        features: {
          0: "Number of SR to be defined together",
          1: "Users based on needs",
          2: "External candidate link",
          3: "Automated translation",
          4: "Unlimited custom SR templates",
          5: "Technical support and guidance"
        }
      }
    },
    cta: {
      free: "Try it for free!",
      essential: "Try it for free!",
      standard: "Try it for free!",
      enterprise: "Contact us"
    }
  },
  contact: {
    header: {
      title: "Meet the team that will drive your success",
      subtitle1: "Have questions or want a personalized demo?",
      subtitle2: "Our team of experts is here to guide you towards success."
    },
    team: {
      damien: {
        name: "Damien",
        role: "CTO"
      },
      alexandre: {
        name: "Alexandre",
        role1: "Head of Customer Success",
        role2: "& Commercial Director"
      },
      emmanuel: {
        name: "Emmanuel",
        role: "Head of AI & Automation"
      }
    },
    buttons: {
      contact: "Let's discuss your project",
      demo: "Book a free demo"
    }
  },
  footer: {
    menu: {
      advantages: "Benefits",
      features: "Features",
      pricing: "Pricing",
      contact: "Contact us"
    },
    legal: {
      legalNotice: "Legal notices",
      copyright: "© 2024 - DC CREATOR - All Rights Reserved"
    }
  },
  process: {
    header: {
      badge: "EASY AND FAST",
      title: "Create your skills report in 3 steps",
      subtitle: "A simple and fast process to transform your CVs into professional skills reports"
    },
    steps: {
      1: {
        title: "Select a template",
        description: "Choose from our professional templates the one that best suits your needs.",
        illustrationText: "Template selection"
      },
      2: {
        title: "Customize your template",
        description: "Add your logo and adjust colors to reflect your company's identity.",
        illustrationText: "Complete customization"
      },
      3: {
        title: "Transform the CV",
        description: "Import the CV and get a complete skills report optimized for your clients.",
        illustrationText: "SR ready to send"
      }
    },
    cta: {
      badge: "AI-POWERED",
      timeValue: "Less than 3 minutes",
      timeLabel: "that's all you need",
      title: "Save 80% of your preparation time",
      subtitle: "Why spend hours on your skills reports when AI can do it in minutes?",
      benefit1: "Professional skills reports generated instantly",
      benefit2: "Complete customization to match your company's brand",
      benefit3: "100% satisfaction guaranteed or your money back",
      button: "Try it free now",
      buttonMobile: "Try free",
      guarantee: "No commitment - Cancel anytime",
      ratingText: "+ 100 satisfied users",
      statLeft: {
        value: "1200+",
        label: "reports generated"
      },
      statRight: {
        value: "100%",
        label: "satisfied clients"
      }
    }
  },
  testimonials: {
    header: {
      badge: "TESTIMONIALS",
      title: "What our clients think",
      subtitle: "Discover how DC Creator transforms recruiters' daily work"
    },
    items: {
      0: {
        author: "Alice Dubois",
        position: "Recruitment Manager",
        content: "DC Creator has revolutionized our recruitment process. The automated generation of skills reports saves us precious time, allowing us to focus on what's essential: human relationships."
      },
      1: {
        author: "François Martin",
        position: "HR Director",
        content: "An essential tool that has transformed our approach to recruitment. Centralization and automation have allowed us to significantly optimize our processes."
      },
      2: {
        author: "Franck Laurent",
        position: "Business Developer",
        content: "Thanks to DC Creator, we've reduced the time spent creating skills reports by 70%. An investment that has considerably improved our efficiency."
      }
    }
  },
  legal: {
    legalNotice: {
      title: "Legal notices"
    },
    tabs: {
      cgu: "Terms of Use",
      cgv: "Terms of Sale"
    },
    cgv: {
      title: "Terms of Sale",
      lastUpdate: "Last updated",
      updateDate: "03/02/2025",
      sections: {
        0: {
          title: "Purpose",
          content: "These Terms of Sale govern the access and use of the DC Creator service, which allows the generation of skills reports from CVs. Any subscription to one of the proposed plans implies full acceptance of these Terms of Sale. DC Creator is a service provided by ZEPHYR AI, a French SAS company with a capital of €1,000, registered with the Paris Trade and Companies Register under SIREN number 940 280 712, SIRET 94028071200016, with its registered office at 26 STREET ANDRE MESSAGER, 33400 TALENCE, FRANCE."
        },
        1: {
          title: "Subscription Plans",
          content: "We offer several subscription plans: a free version limited to 3 uses per month, an essential version with 10 uses per month for 2 users, a pro version with 30 uses per month for 3 users. Customized plans are available for businesses upon request. Prices are indicated in euros excluding taxes (VAT excluded). French VAT at the rate of 20% will be applied additionally for customers established in metropolitan France, in accordance with Article 278 of the French General Tax Code. For professional customers established in the European Union (outside France) with a valid intra-community VAT number, the reverse charge mechanism applies (Article 283-2 of the CGI). For customers established outside the European Union, services are not subject to French VAT. Any change in the legal VAT rate will automatically be reflected in the price including VAT on the effective date of the new rate."
        },
        2: {
          title: "Payments",
          content: "Payments are processed through Stripe by monthly automatic debit. Subscriptions are without commitment and can be cancelled at any time. In case of cancellation, the service remains accessible until the end of the current month, but no further debits will be made. In accordance with Article L441-10 of the French Commercial Code, any late payment will result in the application of late payment penalties equal to three times the legal interest rate in force, as well as a fixed compensation for recovery costs of €40."
        },
        3: {
          title: "Billing",
          content: "An invoice compliant with French legislation will be issued for each debit and transmitted electronically. The Customer expressly agrees to receive invoices electronically."
        },
        4: {
          title: "Cancellation",
          content: "To cancel your subscription, please contact us by email at: {email}. Your subscription will be cancelled at the end of the current month without additional charges. ZEPHYR AI reserves the right to unilaterally terminate any subscription in case of non-compliance with these Terms of Sale, particularly in case of non-payment or fraudulent use of the service.",
          email: "contact@dc-creator.com"
        },
        5: {
          title: "Service Access",
          content: "Access to the service is provided immediately after subscribing to one of the plans. Users can manage their account and track their monthly usage through their dashboard. ZEPHYR AI commits to using its best efforts to ensure the availability of the service 24/7, except in case of planned maintenance or technical incident."
        },
        6: {
          title: "Terms Modification",
          content: "We reserve the right to modify these terms at any time. Users will be informed of any major changes by email. Modifications take effect immediately after their publication on our site. In case of disagreement with the new terms, the Customer has a period of 30 days to cancel their subscription."
        },
        7: {
          title: "Liability Limitation",
          content: "ZEPHYR AI's liability is limited to direct and foreseeable damages resulting from a breach of its contractual obligations. ZEPHYR AI cannot be held responsible for indirect damages such as data loss, loss of opportunity, loss of revenue, or damage to image. ZEPHYR AI's liability is capped at the amount of sums received for the subscription during the last 12 months."
        },
        8: {
          title: "Intellectual Property",
          content: "ZEPHYR AI holds all intellectual property rights related to the DC Creator service. The subscription only grants a right to use the service, without transfer of ownership. The Customer agrees not to reproduce, copy, sell, exchange, or commercially exploit all or part of the service."
        },
        9: {
          title: "Personal Data Protection",
          content: "ZEPHYR AI commits to comply with applicable regulations regarding personal data protection, particularly the General Data Protection Regulation (GDPR) and the French Data Protection Act. The terms of personal data processing are detailed in our Privacy Policy accessible on our website."
        },
        10: {
          title: "Applicable Law and Jurisdiction",
          content: "These Terms of Sale are governed by French law. In case of dispute, the parties will endeavor to find an amicable solution. Failing that, the courts of Paris will have exclusive jurisdiction, notwithstanding plurality of defendants or warranty claims."
        },
        11: {
          title: "Contact",
          content: "For any questions regarding these Terms of Sale, you can contact us at: {email}.",
          email: "contact@dc-creator.com"
        }
      }
    },
    cgu: {
      title: "Terms of Use",
      lastUpdate: "Last updated",
      updateDate: "03/02/2025",
      sections: {
        0: {
          title: "Preamble",
          content: "DC Creator is an online service for human resources professionals, allowing the conversion of resumes into customized skills reports. The service is available in France and Quebec and is designed to help recruiters analyze candidate resumes. These Terms of Use aim to define the rights and obligations of users, as well as to ensure compliance with applicable laws (Bill 25 in Quebec and GDPR in France)."
        },
        1: {
          title: "Definitions",
          content: "<strong>DC Creator</strong>: The provider of the online service accessible at https://dc-creator.com, published by ZEPHYR AI, a French SAS company with a capital of €1,000, registered with the Paris Trade and Companies Register under SIREN number 940 280 712, SIRET 94028071200016.<br><br><strong>User</strong>: Any natural or legal person using DC Creator's service, particularly recruiters and HR managers.<br><br><strong>Service</strong>: The resume to skills report conversion solution provided by DC Creator.<br><br><strong>Resume/CV</strong>: Document containing professional information about a candidate, uploaded by the user.<br><br><strong>Skills report</strong>: Document generated by DC Creator from information extracted from the resume.<br><br><strong>Personal data</strong>: Information allowing the identification of a natural person (name, first name, email, company).<br><br><strong>Candidate Link</strong>: Feature allowing recruiters to send a direct link to candidates so they can generate their own skills report.<br><br><strong>Candidate</strong>: Any person whose resume is uploaded by a user (recruiter) or who uses the \"Candidate Link\" feature.<br><br><strong>Stripe</strong>: Third-party service used for payment management.<br><br><strong>Gemini API</strong>: Natural language processing model developed by Google, used to analyze and extract data from resumes.<br><br><strong>Supabase</strong>: User data storage service, hosted in Frankfurt, Germany, and subject to GDPR."
        },
        2: {
          title: "Purpose of the Terms of Use",
          content: "These Terms of Use aim to define the conditions for using the DC Creator service by users, as well as the responsibilities of each party regarding the protection of personal data and compliance with applicable laws."
        },
        3: {
          title: "Registration and Usage Conditions",
          content: "To access the service, the user must create an account by providing accurate and up-to-date information. The user agrees to maintain the confidentiality of their login credentials and to report any unauthorized use of their account.<br><br>By using our service, the user guarantees having obtained the explicit consent of candidates for the use of their resumes within DC Creator, in accordance with Article 6.1.a of the GDPR and Article 14 of Quebec's Bill 25.<br><br><strong>The user indemnifies and holds DC Creator harmless against any action, claim or recourse initiated by a candidate or a third party relating to the content of the CVs provided by the user and to the information extracted from these CVs.</strong>"
        },
        4: {
          title: "DC Creator's Obligations",
          content: "DC Creator commits to:<br><br>- Provide continuous and secure access to the service, subject to maintenance operations and unforeseen events.<br>- Delete candidate resumes immediately after processing by the Gemini API.<br>- Protect users' personal data in accordance with Bill 25 (Quebec) and GDPR (France).<br>- Implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk.<br>- Notify the user of any personal data breach within the timeframes provided by applicable regulations."
        },
        5: {
          title: "Use of Gemini API (Google AI)",
          content: "<strong>Data processing by Gemini API</strong>:<br><br>When using the service, candidate resumes uploaded by users or via the Candidate Link are sent to the Gemini API for analysis and data extraction. Users acknowledge and accept that information contained in the resumes (including personal data) will be transmitted to Google.<br><br><strong>Data protection</strong>:<br><br>DC Creator does not retain resumes after sending them to the Gemini API. Data is deleted immediately after processing. Google applies advanced security measures, including encryption.<br><br><strong>International data transfers</strong>:<br><br>The use of the Gemini API involves an international transfer of personal data outside the EU to Google. This transfer is governed by the European Commission's Standard Contractual Clauses and appropriate supplementary measures to ensure an adequate level of protection for personal data in accordance with the GDPR.<br><br><strong>User and candidate consent</strong>:<br><br>Users must obtain explicit consent from candidates before using their resumes in DC Creator. This consent must be free, specific, informed, and unambiguous, in accordance with GDPR and Bill 25 requirements."
        },
        6: {
          title: "User Data Storage",
          content: "User data is stored on Supabase, hosted in Frankfurt, Germany, in compliance with GDPR requirements.<br><br>In accordance with Articles 15 to 22 of the GDPR and equivalent provisions of Bill 25, users can exercise their rights of access, rectification, erasure, restriction of processing, portability, and objection by sending an email to contact@dc-creator.com.<br><br>DC Creator commits to processing any request within 30 days and to deleting data within 30 days, unless there is a legal obligation to retain it."
        },
        7: {
          title: "\"Candidate Link\" Feature",
          content: "Recruiters can send a link to candidates so they can generate their own skills report. Before using it, the candidate must accept the Terms of Use and give explicit consent to the processing of their personal data.<br><br>The recruiter remains responsible for informing the candidate of this process beforehand and obtaining their initial agreement before sending the link, in accordance with the principle of transparency of the GDPR and Bill 25."
        },
        8: {
          title: "Payment and Billing",
          content: "The service is accessible via different subscription plans (free, essential, standard, enterprise).<br><br>Payments are processed through Stripe, which acts as a data processor for payment data.<br><br>Banking data is not stored by DC Creator but directly by Stripe, which complies with the PCI DSS (Payment Card Industry Data Security Standard).<br><br><strong>The user is prohibited from any reverse engineering, attempt to access or extract elements protected by intellectual property rights held by DC Creator.</strong>"
        },
        9: {
          title: "Right of Withdrawal",
          content: "In accordance with Article L221-18 of the French Consumer Code, the user has a right of withdrawal for 14 days from subscription, provided the service has not been used.<br><br>To exercise this right, the user must notify their decision by email to contact@dc-creator.com.<br><br>In case of withdrawal, DC Creator will process the refund within 14 days after notification, using the same payment method as the one used for the initial transaction."
        },
        10: {
          title: "Personal Data and Confidentiality",
          content: "As a data controller, DC Creator collects only the data necessary for account creation and service use, in accordance with the data minimization principle (Article 5.1.c of the GDPR).<br><br>Resumes are not retained and are deleted after processing, in accordance with the storage limitation principle (Article 5.1.e of the GDPR).<br><br>DC Creator commits not to sell, rent, or transfer personal data to third parties, except legal obligation.<br><br>For more information on personal data processing, please consult our Privacy Policy accessible on our website."
        },
        11: {
          title: "Customer Support",
          content: "For any questions or assistance related to the service, contact our team at contact@dc-creator.com.<br><br>DC Creator commits to responding within 48 business hours. <strong>Our customer support is available Monday to Friday from 9am to 5pm (French time), with a response time of 48 business hours.</strong>"
        },
        12: {
          title: "Advertising and Client References",
          content: "DC Creator may use clients' names and logos as commercial references, subject to their prior written agreement.<br><br>This authorization can be withdrawn at any time by simple request to contact@dc-creator.com."
        },
        13: {
          title: "Modification of Terms of Use",
          content: "DC Creator reserves the right to modify these Terms of Use. Users will be informed by email or notification on the site 15 days before they come into effect.<br><br>In case of disagreement with the new conditions, the user can terminate their subscription before they come into effect. Otherwise, the new conditions will be deemed accepted."
        },
        14: {
          title: "Limitation of Liability",
          content: "DC Creator is not responsible for indirect damages (data loss, loss of profit, loss of opportunity, damage to image) resulting from the use or inability to use the service.<br><br>DC Creator's liability is limited to direct and foreseeable damages, up to the amounts paid by the user during the last 12 months.<br><br>DC Creator cannot be held responsible for the content of resumes and generated skills reports."
        },
        15: {
          title: "Applicable Law and Jurisdiction",
          content: "These Terms of Use are governed by French law for users in France, and by Quebec law for users in Canada.<br><br>In case of dispute, the parties will endeavor to find an amicable solution. Failing that, the competent courts will be those of Paris (France) or Montreal (Canada), notwithstanding plurality of defendants or warranty claims."
        },
        16: {
          title: "Force Majeure",
          content: "DC Creator cannot be held responsible for non-execution or delay in the execution of its obligations in case of force majeure, as defined by the jurisprudence of French and Quebec courts, particularly in case of natural disasters, pandemics, massive computer attacks, telecommunication network failures, or decisions of governmental authorities."
        }
      }
    }
  },
  cookies: {
    message: "We use cookies to enhance your experience on our site.",
    detailedMessage: "This site uses cookies to improve your experience, analyze traffic and personalize content. You can choose which ones you accept.",
    moreInfo: "Learn more",
    accept: "Accept",
    acceptAll: "Accept all",
    reject: "Decline",
    rejectAll: "Decline all",
    essentialOnly: "Essential only",
    settings: {
      title: "Cookie Settings",
      description: "You can choose which categories of cookies you want to enable. Essential cookies are necessary for the site to function and cannot be disabled.",
      save: "Save",
      cancel: "Cancel",
      essential: {
        title: "Essential cookies",
        description: "Necessary for the site to function. They enable the use of basic features such as navigation and access to secure areas."
      },
      analytics: {
        title: "Analytics cookies",
        description: "Allow us to understand how you use the site in order to improve it (Google Analytics)."
      },
      chat: {
        title: "Chat cookies",
        description: "Enable the live chat function to offer you assistance (Tawk.to)."
      }
    }
  },
  domains: {
    badge: "ALL SECTORS COVERED",
    title: "Your multi-sector recruitment tool",
    subtitle: "Whether you're recruiting in IT, finance or construction, our AI adapts to all profiles and enhances every professional journey",
    items: {
      0: "Purchasing & Logistics",
      1: "IT & Digital",
      2: "Finance & Accounting",
      3: "Construction & Engineering",
      4: "Healthcare & Medical",
      5: "And many more"
    },
    more: "And all other business sectors! Our tool adapts to your specific needs.",
    cta: "Try it for free"
  }
} 